import { useParams } from "react-router-dom";
import blogs from "@/datasets/blogs.json";
import { useEffect, useState } from "react";
import DotPattern from "@/wireframes/backgrounds/pattern/dot-pattern";
import HeroSectionWithProps from "@/wireframes/page-sections/hero/HeroSectionWithProps";
import HeroSectionWithProp from "@/wireframes/page-sections/hero/HeroSectionWithProp";
import Particles from "@/wireframes/special-effects/particles";
import { useTheme } from "@/wireframes/theme/theme-provider";

const BlogHeaderSection: React.FC = () => {
  let { id } = useParams<{ id: string }>();
  const blog = blogs.find((blog) => blog.id === parseInt(id ?? "", 10));
  const { theme } = useTheme();

  const [color, setColor] = useState("#ffffff");
  
  useEffect(() => {
    setColor(theme === "dark" ? "#ffffff" : "#000000");
  }, []);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  if (!blog) {
    return (
      <div className="text-center justify-center items-center">
        Service not found
      </div>
    );
  }

  const PatternComponent = () => (
    <Particles
  className="absolute inset-0 "
  quantity={100}
  ease={80}
  color={color}
  refresh
/>
  );


  const BlogsComponent = () => {
    return <></>;
  };

  return (
    <HeroSectionWithProp
      title={blog.title}
      description={blog["short_description"]}
      PatternComponent={PatternComponent}
      image={blog.image}
      marqueeList={[]}
      categories={blog.categories}
      MarqueeCardComponent={BlogsComponent}
    />
  );
};

export default BlogHeaderSection;
