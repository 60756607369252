import React, { ChangeEvent, FormEvent, useEffect, useState } from "react";
import { useTheme } from "@/wireframes/theme/theme-provider";
import { useTranslation } from "react-i18next";
import ReCAPTCHAComponent from "@/components/header/ReCAPTCHAComponent";
import { Input } from "@/components/ui/input";
import { Textarea } from "@/components/ui/textarea";
import HeroSectionChildren from "@/wireframes/page-sections/hero/HeroSectionChildren";
import GearHub4 from "@/assets/logo/gearhub4.png";
import TypingAnimation from "@/wireframes/text-animations/typing-animation";
import { Button } from "@/components/ui/button";
import { isContactNumberValid, isDescriptionValid, isEmailValid, isNameValid } from "@/utilities/formValidation";
import useContactUsBackend from "@/api/firebase/core/contact-us/useContactUsBackend";
import { Label } from "@/components/ui/label";
import { Checkbox } from "@/components/ui/checkbox";

const ContactUsForm: React.FC = () => {
  const { theme } = useTheme();
  const [color, setColor] = useState("#ffffff");

  type ThemeStyles = {
    bgColor: string;
    textColor: string;
    imgSrc: string;
  };

  const themeStyles: Record<string, ThemeStyles> = {
    light: {
      bgColor: "bg-neutral-100",
      textColor: "text-light-text",
      imgSrc: GearHub4,
    },
    dark: {
      bgColor: "bg-dark-bg",
      textColor: "text-dark-text",
      imgSrc: GearHub4,
    },
    system: {
      bgColor: "bg-light-bg", // Default to light theme for system
      textColor: "text-light-text",
      imgSrc: GearHub4,
    },
  };

  const currentThemeStyles = themeStyles[theme] || themeStyles.light;
  const [currentImage] = useState(currentThemeStyles.imgSrc);

  useEffect(() => {
    setColor(theme === "dark" ? "#ffffff" : "#000000");
  }, [theme]);

  const [formData, setFormData] = useState({
    fullName: "",
    email: "",
    contact: "",
    message: "",
  });

  const [validationData, setValidationData] = useState({
    fullNameValidation: { isValid: true, message: "" },
    emailValidation: { isValid: true, message: "" },
    contactValidation: { isValid: true, message: "" },
    messageValidation: { isValid: true, message: "" },
    checkboxValidation: { isValid: true, message: "" },

  });

  const handleChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const [isCheckedFirst, setIsCheckedFirst] = useState(false);
  const [isCheckedSecond, setIsCheckedSecond] = useState(false);

  const handleFirstCheckboxChange = () => {
    const newCheckedState = !isCheckedFirst;
    setIsCheckedFirst(newCheckedState);
  
  };

  const handleSecondCheckboxChange = () => {
    setIsCheckedSecond(!isCheckedSecond);
  };

  const { createContactUs } = useContactUsBackend();

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    try {
      const { fullName, email, contact, message } = formData;
      const fullNameValidation = isNameValid(fullName);
      const emailValidation = isEmailValid(email);
      const contactValidation = isContactNumberValid(contact);
      const messageValidation = isDescriptionValid(message);

      setValidationData({
        fullNameValidation,
        emailValidation,
        contactValidation,
        messageValidation,
        checkboxValidation: {
          isValid: isCheckedSecond,
          message: isCheckedSecond ? "" : "This field is required.",
        },
      });

      if (
        fullNameValidation.isValid &&
        emailValidation.isValid &&
        contactValidation.isValid &&
        messageValidation.isValid &&
        isCheckedSecond
      ) {
        await createContactUs(formData);
        setFormData({
          fullName: "",
          email: "",
          contact: "",
          message: "",
        });
        setIsCheckedFirst(false);
        setIsCheckedSecond(false);

        // toast({
        //   title: "Form Submitted",
        //   description: "Your message has been sent successfully.",
        // });

        alert('Your message has been submitted successfully. We will get back to you soon.');
        // window.location.href = "";
      } 
      
    } catch (error) {
      console.error('Error adding document: ', error);
      alert('There was an error submitting your message. Please try again.');
    }
  };

  const RightChild = (
    <form className="rounded pt-6 mb-4 p-4" method="post">
      <div className="grid grid-cols-1 lg:grid-cols-3 gap-4 mb-2">
        <div className="mb-4">
          <label className="block text-start text-sm font-bold mb-2" htmlFor="fullName">
          Full Name 
          <span className="text-accent text-xl"> *</span>
          </label>
          <Input
            className="shadow appearance-none border rounded w-full py-2 text-gray-700 bg-transparent leading-tight focus:outline-none focus:shadow-outline text-black dark:text-white"
            id="full_name"
            name="fullName"
            type="text"
            placeholder="Full name"
            value={formData.fullName}
            onChange={handleChange}
          />
           {!validationData.fullNameValidation.isValid && (
                      <p className="text-accent text-start text-sm">{validationData.fullNameValidation.message}</p>
                    )}
        </div>
        <div className="mb-4">
          <label className="block text-start text-sm font-bold mb-2" htmlFor="contact">
            Contact
          <span className="text-accent text-xl"> *</span>

          </label>
          <Input
            className="shadow appearance-none border rounded w-full py-2 bg-transparent leading-tight focus:outline-none focus:shadow-outline text-black dark:text-white"
            id="contact"
            name="contact"
            type="text"
            placeholder="Contact"
            value={formData.contact}
            onChange={handleChange}
          />
          {!validationData.contactValidation.isValid && (
            <p className="text-accent text-start text-sm">
              {validationData.contactValidation.message}
            </p>
          )}
        </div>
        <div className="mb-4">
          <label className="block text-start text-sm font-bold mb-2" htmlFor="email">
            Email
          <span className="text-accent text-xl"> *</span>

          </label>
          <Input
            className="shadow appearance-none border rounded w-full py-2 px-3 bg-transparent leading-tight focus:outline-none focus:shadow-outline text-black dark:text-white"
            id="email"
            name="email"
            type="email"
            placeholder="Email"
            value={formData.email}
            onChange={handleChange}
          />
          {!validationData.emailValidation.isValid && (
            <p className="text-accent text-start text-sm">
              {validationData.emailValidation.message}
            </p>
          )}
        </div>
      </div>

      <div className="mb-4">
        <label className="block text-start text-sm font-bold mb-2" htmlFor="message">
          Message
          <span className="text-accent text-xl"> *</span>

        </label>
        <Textarea
          id="message"
          name="message"
          rows={4}
          value={formData.message}
          onChange={handleChange}
          placeholder="Message"
          className="rounded"
        />
        {!validationData.messageValidation.isValid && (
          <p className="text-accent text-start text-sm">
            {validationData.messageValidation.message}
          </p>
        )}
      </div>
      <div className="mb-4">
      <div className="text-start">
                  <div className="flex items-center space-x-2">
                    <Checkbox
                      name="firstCheckbox"
                      checked={isCheckedFirst}
                      onCheckedChange={handleFirstCheckboxChange}
                      className="peer h-[1rem] w-[18px] p-0 rounded-none  border border-gray-300 bg-white shadow-sm focus:outline-none focus:ring-1 focus:ring-none disabled:cursor-not-allowed disabled:opacity-50 data-[state=checked]:bg-foreground data-[state=checked]:border-muted-foreground data-[state=checked]:text-background dark:border-gray-600  dark:focus:ring-blue-400 dark:data-[state=checked]:bg-foreground dark:data-[state=checked]:text-gray-900"
                    />

                    <label
                      htmlFor="firstCheckbox"
                      className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
                    >
                      I'm intrested in hearing how GearHub can help my
                      organisation
                    </label>
                  </div>
                  <div className="flex items-center space-x-2 mt-4" style={{ 
                    display: "flex",
                    alignItems: "flex-start"
                   }}>
                    <Checkbox
                      name="secondCheckbox"
                      checked={isCheckedSecond}
                      onCheckedChange={handleSecondCheckboxChange}
                      className="peer h-[1rem] w-[18px] p-0 rounded-none  border border-gray-300 bg-white shadow-sm focus:outline-none focus:ring-1 focus:ring-none disabled:cursor-not-allowed disabled:opacity-50 data-[state=checked]:bg-foreground data-[state=checked]:border-muted-foreground data-[state=checked]:text-background dark:border-gray-600 dark:focus:ring-blue-400 dark:data-[state=checked]:bg-foreground dark:data-[state=checked]:text-gray-900"
                    />

                    <label
                      htmlFor="secondCheckbox"
                      className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
                    >
                      I agree, to allow GearHub to store and process my personal
                      data. View our{" "}
                      <a
                        href="/privacy-policy"
                        className={`underline underline-offset-4 text-foreground hover:text-muted `}
                      >
                        privacy policy
                      </a>{" "}
                      to learn more.
                      <span className="text-accent text-xl"> *</span>
                      {!validationData.checkboxValidation.isValid && (
                        <p className="text-accent text-start text-sm">
                          {validationData.checkboxValidation.message}
                        </p>
                      )}
                    </label>
                  </div>
      </div>
      </div>
      {/* <div className="mb-4">
      <div className="flex justify-center mb-4 mt-6 mb-6"> <ReCAPTCHAComponent /> </div> 
      </div> */}
      <div className="z-10 flex items-center justify-center" onClick={handleSubmit}>
        <Button className="shadow-2xl bg-foreground text-background z-10">
          SUBMIT
        </Button>
      </div>
    </form>
  );

  const LeftChild = (
    <div className="relative flex h-[460px] w-full flex-col items-center justify-center overflow-hidden hidden lg:block">
      <span className="pointer-events-none whitespace-pre-wrap bg-gradient-to-b from-white to-gray-300/80 bg-clip-text text-center text-2xl font-semibold leading-none ">
        <div className="items-center justify-center text-center mx-auto mb-2 w-1/2 sm:w-1/2 md:w-1/2 lg:w-1/2 xl:w-1/2">
          <img
            className="bounce"
            src={currentImage}
            alt="GearHub"
            style={{
              zIndex: "1",
              position: "relative",
            }}
          />
        </div>
      </span>
    </div>
  );

  return (
    <HeroSectionChildren id="contact-us">
      <div className="mx-auto mb-12 flex flex-col justify-center items-start">
        <header className="text-start">
          <TypingAnimation
            className="text-3xl sm:text-3xl md:text-3xl lg:text-5xl text-wrap text-start font-bold text-black dark:text-white"
            text="Get In Touch"
            duration={90}
          />
        </header>
        <div className="text-start mb-8">
          <h5 className="text-md sm:text-md md:text-lg lg:text-xl font-normal mb-4">
            Share your requirements with us and our{" "}
            <span className="text-foreground"> top strategists </span>would
            contact you.
          </h5>
        </div>

        <div className="w-full flex justify-center">
          <div className="w-full max-w-3xxl flex flex-wrap">
            <div className="hidden md:flex w-full h-full md:w-1/2 items-center justify-center">
              {LeftChild}
            </div>
            <div className="md:flex w-full h-full md:w-1/2 items-center justify-center" style={{ zIndex:10 }}>
              {RightChild}
            </div>
          </div>
        </div>
      </div>
    </HeroSectionChildren>
  );
};

export default ContactUsForm;
