import { useParams } from "react-router-dom";
import blogs from "@/datasets/blogs.json";
import { useEffect, useState } from "react";
import HeroSectionChildren from "@/wireframes/page-sections/hero/HeroSectionChildren";
import Particles from "@/wireframes/special-effects/particles";
import { useTheme } from "@/wireframes/theme/theme-provider";

const BlogDetails: React.FC = () => {
  const { theme } = useTheme();

  let { id } = useParams<{ id: string }>();
  const blog = blogs.find((blog) => blog.id === parseInt(id ?? "", 10));

  const [color, setColor] = useState("#ffffff");

  useEffect(() => {
    setColor(theme === "dark" ? "#ffffff" : "#000000");
  }, []);
  
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  if (!blog) {
    return (
      <div className="text-center justify-center items-center">
        Service not found
      </div>
    );
  }


  
 
  return (
    <HeroSectionChildren id="blogs">
      <div>
        <section className="mb-10 px-4">
          <div
            className="text-base text-start text-balance"
            dangerouslySetInnerHTML={{ __html: blog.description }}
          />
            <Particles
  className="absolute inset-0 "
  quantity={100}
  ease={80}
  color={color}
  refresh
/>
        </section>
      
      </div>
    
    </HeroSectionChildren>
  );
};

export default BlogDetails;
