/* eslint-disable @typescript-eslint/no-unused-vars */
// src/pages/HomePage.tsx
import React, { useEffect, useState } from "react";
import HeroSectionChildren from "@/wireframes/page-sections/hero/HeroSectionChildren";
import { useTheme } from "@/wireframes/theme/theme-provider";
import GearHub1 from "@/assets/logo/gearhub1.png";

import { useTranslation } from "react-i18next";
import Particles from "@/wireframes/special-effects/particles";
import TypingAnimation from "@/wireframes/text-animations/typing-animation";
type ThemeStyles = {
  bgColor: string;
  textColor: string;
  imgSrc: string;
};

const HeaderSection: React.FC = () => {
  const { theme } = useTheme();
  const { t } = useTranslation();
  const themeStyles: Record<string, ThemeStyles> = {
    light: {
      bgColor: "bg-neutral-100",
      textColor: "text-light-text",
      imgSrc: GearHub1,
    },
    dark: {
      bgColor: "bg-dark-bg",
      textColor: "text-dark-text",
      imgSrc: GearHub1,
    },
    system: {
      bgColor: "bg-light-bg", // Default to light theme for system
      textColor: "text-light-text",
      imgSrc: GearHub1,
    },
  };
  const [color, setColor] = useState("#ffffff");

  useEffect(() => {
    setColor(theme === "dark" ? "#ffffff" : "#000000");
  }, [theme]);

  const currentThemeStyles = themeStyles[theme] || themeStyles.light;

  const [currentImage, setCurrentImage] = useState(currentThemeStyles.imgSrc);

  const RightChild = (
    <div className="relative flex h-[450px] w-full flex-col items-center justify-center overflow-hidden">
      <span className="pointer-events-none whitespace-pre-wrap bg-gradient-to-b from-white to-gray-300/80 bg-clip-text text-center text-2xl font-semibold leading-none">
        <div className="items-center justify-center text-center mx-auto w-1/1 sm:w-1/2 md:w-1/2 lg:w-1/1 xl:w-1/1">
          <img
            className="bounce"
            src={currentImage}
            alt="GearHub"
            style={{
              zIndex: "1",
              position: "relative",
            }}
          />
        </div>
      </span>
    </div>
  );

  const LeftChild = (
    <div className={`container mx-auto`}>
      <header className={`text-start `}>
      <TypingAnimation
      className="text-4xl font-bold text-black dark:text-white"
      text="Hi, Welcome to GearHub"
      duration={90}
    />
      
      </header>
    </div>
  );

  return (
    <>
      <HeroSectionChildren id="home">
        <div className="flex flex-col md:flex-row w-full justify-center items-center sm:w-1/1 md:w-1/1 pt-10">
          <div className="w-full md:w-1/2 text-center md:text-left pb-5 sm:pb-25 pt-10">
            {LeftChild}
          </div>
          <div className="w-full md:w-1/2  text-center md:text-right md:mt-0 px-4 md:px-8">
            {RightChild}
          </div>
        </div>
        <Particles
          className="absolute inset-0"
          quantity={100}
          ease={80}
          color={color}
          refresh
        />
      </HeroSectionChildren>
    </>
  );
};

export default HeaderSection;
