// /* eslint-disable @typescript-eslint/no-unused-vars */
// import { FaEnvelope, FaPhone } from "react-icons/fa";

// const SupportPage: React.FC = () => {

//   const sections = [
   
//     {
//       title: "LONDON",
//       items: [
//         "UK,",
//         { type: "phone", value: "+44 748 884 8086" },
//         { type: "email", value: "info@gearhub.design" },
//       ],
//     },
//   ];

//   return (
//     <footer className={`border rounded py-12`}>
//       <div className="container mx-auto px-4">
//         <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-8 text-center md:text-left">
//           {sections.map((section, index) => (
//             <div key={index} className="col-span-1">
//               <h5 className="text-lg font-bold mb-4">{section.title}</h5>
//               <ul>
//                 {section.items.map((item, key) => (
//                   <li key={key} className="mb-2">
//                     {typeof item === "string" ? (
//                       <p>{item}</p>
//                     ) : item.type === "phone" ? (
//                       <div className="flex items-center justify-center md:justify-start">
//                         <FaPhone className="mr-2" />
//                         <p>{item.value}</p>
//                       </div>
//                     ) : item.type === "email" ? (
//                       <div className="flex items-center justify-center md:justify-start">
//                         <FaEnvelope className="mr-2" />
//                         <p>{item.value}</p>
//                       </div>
//                     ) : null}
//                   </li>
//                 ))}
//               </ul>
//             </div>
//           ))}
//           <div className="col-span-1">
//             <h5 className="text-lg flex items-center justify-center md:justify-end font-bold mb-3">
//               NEED SUPPORT
//             </h5>
//             <ul>
//               <li className="mb-2 md:text-end">
//                 <div className="flex items-center justify-center md:justify-end mt-2">
//                   <FaPhone className="mr-2" />
//                   <p>+44 748 884 8086</p>
//                 </div>
//               </li>
//               <li className="mb-2">
//                 <div className="flex items-center justify-center md:justify-end">
//                   <FaEnvelope className="mr-2" />
//                   <p>info@gearhub.design</p>
//                 </div>
//               </li>
//             </ul>
//           </div>
//         </div>
//       </div>
//     </footer>
//   );
// };

// export default SupportPage;

/* eslint-disable @typescript-eslint/no-unused-vars */
import { FaEnvelope, FaPhone } from "react-icons/fa";

const SupportPage: React.FC = () => {
  const sections = [
    {
      title: "LONDON",
      items: [
        "Gearhub 3rd Floor<br/>86-90 Paul Street<br/>EC2A 4NE<br/>UK",
        // { type: "phone", value: "+44 748 884 8086" },
        // { type: "email", value: "info@gearhub.design" },
      ],
    },
  ];

  const ContactItem = ({ item }: { item: any }) => {
    if (typeof item === "string") {
      return <p dangerouslySetInnerHTML={{ __html: item }}>
        
        </p>;
    }

    return (
      <div className="flex items-center justify-center md:justify-start">
        {item.type === "phone" ? (
          <>
            <FaPhone className="mr-2" />
            <p>{item.value}</p>
          </>
        ) : (
          <>
            <FaEnvelope className="mr-2" />
            <p>{item.value}</p>
          </>
        )}
      </div>
    );
  };

  const Section = ({ section }: { section: any }) => (
    <div className="col-span-1">
      <h5 className="text-lg font-bold mb-4">{section.title}</h5>
      <ul>
        {section.items.map((item: any, key: number) => (
          <li key={key} className="mb-2">
            <ContactItem item={item} />
          </li>
        ))}
      </ul>
    </div>
  );

  return (
    <footer className="border rounded py-12">
      <div className="container mx-auto px-4">
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-8 text-center md:text-left">
          {sections.map((section, index) => (
            <Section key={index} section={section} />
          ))}
          <div className="col-span-1">
          </div>
          <div className="col-span-1">
            <h5 className="text-lg flex items-center justify-center md:justify-start font-bold mb-3">
              NEED SUPPORT
            </h5>
            <ul>
              {/* <li className="mb-2 md:text-start">
                <div className="flex items-center justify-center md:justify-start mt-2">
                  <FaPhone className="mr-2" />
                  <p>+44 748 884 8086</p>
                </div>
              </li> */}
              
              <li className="mb-2">
                <div className="flex items-center justify-center md:justify-start">
                  <FaEnvelope className="mr-2" />
                  <p>info@gearhub.design</p>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default SupportPage;