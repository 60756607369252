/* eslint-disable @typescript-eslint/no-unused-vars */
import DefaultLayout from "@/wireframes/layouts/default";
import ContactUsHeaderSection from "./contact-us/contact-us-headerSection";
import Map from "@/components/Map";
import ContactUsForm from "@/page-sections/contact-us/contact-us-form";
import Header from "@/components/header/Header";
import Footer from "@/components/header/Footer";
import SupportPage from "./contact-us/support-page";
import Particles from "@/wireframes/special-effects/particles";
import { useEffect, useState } from "react";
import { useTheme } from "@/wireframes/theme/theme-provider";
import Services from "./home/services";

const ServicesPage: React.FC = () => {

  const { theme } = useTheme();

  const [color, setColor] = useState("#ffffff");
  
  useEffect(() => {
    setColor(theme === "dark" ? "#ffffff" : "#000000");
  }, []);

  return (
    <>
      <DefaultLayout header={<Header />} footer={<Footer />}>
        <div className="section-colors pb-10">
          {/* <ContactUsHeaderSection /> */}
        </div>
        <div className="section-colors pt-5">
        <div className="relative flex w-full pt-5">
          <Services />
          <Particles
  className="absolute inset-0 "
  quantity={100}
  ease={80}
  color={color}
  refresh
/>
</div>
        </div>
        <div className="section-colors">
          {/* <SupportPage /> */}
          {/* <Map /> */}
        </div>
        {/* <div className="section-colors">
          <GetInTouchPage />
        </div> */}
      </DefaultLayout>
    </>
  );
};

export default ServicesPage;
