/* eslint-disable @typescript-eslint/no-unused-vars */
import { cn } from "@/lib/utils";
import DotPattern from "@/wireframes/backgrounds/pattern/dot-pattern";
import HeroSectionChildren from "@/wireframes/page-sections/hero/HeroSectionChildren";
import HeroSectionWithProps from "@/wireframes/page-sections/hero/HeroSectionWithProps";
import Particles from "@/wireframes/special-effects/particles";
import { useTheme } from "@/wireframes/theme/theme-provider";
import { useEffect, useState } from "react";

import GearHub2 from "@/assets/logo/gearhub2.png";
import GradualSpacing from "@/wireframes/text-animations/gradual-spacing";
import TypingAnimation from "@/wireframes/text-animations/typing-animation";

const AboutUsHeaderSection: React.FC = () => {
  const { theme } = useTheme();

  // const marqueeList = [
  //  {}
  // ]

  const [color, setColor] = useState("#ffffff");
 
  useEffect(() => {
    setColor(theme === "dark" ? "#ffffff" : "#000000");
  }, [theme]);

  const PatternComponent = () => (
    <Particles
        className="absolute inset-0"
        quantity={100}
        ease={80}
        color={color}
        refresh
      />
  );
  const MarqueeCardComponent = ({
    imgLight,
    imgDark,
  }: {
    imgLight: string;
    imgDark: string;
  }) => {
    const logo = theme === "dark" ? imgLight : imgDark;
    return (
      <div
        className={cn(
          "relative cursor-pointer overflow-hidden rounded-xl transition duration-300",
          "w-full md:w-1/2 lg:w-1/3 xl:w-1/4 px-2 mb-4"
        )}
      >
        <div className="w-full h-20 md:h-12 lg:h-14 xl:h-20 flex justify-center items-center">
          <img
            className="w-full h-full object-contain filter grayscale hover:grayscale-0 transition duration-300"
            alt="company"
            src={logo}
          />
        </div>
      </div>
    );
  };

  

  type ThemeStyles = {
    bgColor: string;
    textColor: string;
    imgSrc: string;
  };
  const themeStyles: Record<string, ThemeStyles> = {
    light: {
      bgColor: "bg-neutral-100",
      textColor: "text-light-text",
      imgSrc: GearHub2,
    },
    dark: {
      bgColor: "bg-dark-bg",
      textColor: "text-dark-text",
      imgSrc: GearHub2,
    },
    system: {
      bgColor: "bg-light-bg", // Default to light theme for system
      textColor: "text-light-text",
      imgSrc: GearHub2
    },
  };
 
 

  const currentThemeStyles = themeStyles[theme] || themeStyles.light;

  const [currentImage, setCurrentImage] = useState(
    currentThemeStyles.imgSrc
  );




  
  const RightChild = (
    <div className="relative flex  w-full flex-col items-center justify-center overflow-hidden hidden lg:block">
      <span className="pointer-events-none whitespace-pre-wrap bg-gradient-to-b from-white to-gray-300/80 bg-clip-text text-center text-2xl font-semibold leading-none ">
        <div className="items-center justify-center text-center mx-auto mb-2 w-1/2 sm:w-1/2 md:w-1/2 lg:w-1/2 xl:w-1/2">
          <img
            className=""
            src={currentImage}
            alt="GearHub"
            style={{
              zIndex: "1",
              position: "relative",
            }}
          />
        </div>
      </span>
    </div>
  );

  const LeftChild = (
    <div className="w-full md:w-1/1 text-center md:text-left px-4 md:px-8 pt-10 lg:pt-20 xl:pt-20 ">
     
    <header className={`text-start mb-8 px-4 sm:my-10  pt-10`}>
    <TypingAnimation
      className="text-3xl sm:text-3xl md:text-3xl lg:text-5xl text-wrap text-start font-bold text-black dark:text-white"
      text="About Us"
      duration={90}
    />
          {/* <GradualSpacing
            className="text-3xl sm:text-3xl md:text-3xl lg:text-5xl text-wrap text-start leading-normal font-bold mt-20 sm:mt-12 md:mt-8 lg:mt-10"
            text={"About Us"}
          /> */}
        </header>
       
        <section className="mb-10 px-4">
          <p className={`text-lg text-start max-w-3xl sm:text-md`}>
          At GearHub, we are driven by a dynamic and visionary team of entrepreneurs who share a
        passion for driving positive change. Our mission is to contribute to a brighter future by
        delivering cutting-edge smart solutions tailored to companies of all sizes. We believe in
        empowering businesses to streamline their operations, enhance productivity, and achieve
        optimal performance.
        Our platform offers the tools and connectivity needed to increase project execution speed
        and amplify market impact. By making strategic decisions and leveraging our innovative
        solutions, your business can reach new heights of efficiency and success.
        GearHub is committed to fostering a collaborative environment where businesses can thrive.
        We provide the resources and expertise necessary to navigate the complexities of today's
        market and achieve sustainable growth. Our solutions are designed to be adaptable,
        ensuring that we meet the unique needs of each client
                      </p>
        </section>
     

     </div>
   
  );
  
  return (
    <>
     
       <HeroSectionChildren id="about-us">
        <div className="flex flex-col md:flex-row w-full justify-center items-center sm:w-1/1 md:w-1/1">
          <div className="w-full md:w-1/2 text-center md:text-left pb-5 sm:pb-25">
            {LeftChild}
          </div>
          <div className="w-full md:w-1/2  text-center md:text-right md:mt-0 px-4 md:px-8">
            {RightChild}
          </div>
        </div>
        <Particles
          className="absolute inset-0"
          quantity={100}
          ease={80}
          color={color}
          refresh
        />
      </HeroSectionChildren>
       
    </>
  );
};

export default AboutUsHeaderSection;
