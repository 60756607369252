import React, { useEffect, useState } from "react";
import HeroSectionChildren from "@/wireframes/page-sections/hero/HeroSectionChildren";
import { VelocityScroll } from "@/wireframes/components/scroll-based-velocity";
import { useTheme } from "@/wireframes/theme/theme-provider";
import GearHub1 from "@/assets/logo/gearhub1.png";
import BlurIn from "@/wireframes/text-animations/blur-in";
import Particles from "@/wireframes/special-effects/particles";
import TypingAnimation from "@/wireframes/text-animations/typing-animation";
const GetInTouchPage: React.FC = () => {
  const { theme } = useTheme();

  type ThemeStyles = {
    bgColor: string;
    textColor: string;
    imgSrc: string;
  };
  const themeStyles: Record<string, ThemeStyles> = {
    light: {
      bgColor: "bg-neutral-100",
      textColor: "text-light-text",
      imgSrc: GearHub1,
    },
    dark: {
      bgColor: "bg-dark-bg",
      textColor: "text-dark-text",
      imgSrc: GearHub1,
    },
    system: {
      bgColor: "bg-light-bg", // Default to light theme for system
      textColor: "text-light-text",
      imgSrc: GearHub1
    },
  };
  const [color, setColor] = useState("#ffffff");
 
  useEffect(() => {
    setColor(theme === "dark" ? "#ffffff" : "#000000");
  }, [theme]);

 

  const currentThemeStyles = themeStyles[theme] || themeStyles.light;

  const [currentImage, setCurrentImage] = useState(
    currentThemeStyles.imgSrc
  );

  const RightChild = (
    <>
    <header className={`text-start mb-4 `}>
          {/* <h5 className="text-2xl font-normal underline underline-offset-8 px-4">
            LET’S WORK TOGETHER<span className="text-primary"> .</span>{" "}
          </h5> */}
          <p className="text-5xl mt-4 leading-normal px-4">
          Let’s build your  <span className="text-foreground">venture,</span> together.
          </p>
        </header>
        <section className="mb-4">
          <p className={`text-lg text-start  max-w-3xl mt-2 px-4`}>
            Our platform offers the tools and connectivity needed to increase
            project execution speed and amplify market impact. By making
            strategic decisions and leveraging our innovative solutions, your
            business can reach new heights of efficiency and success.
          </p>
        </section>
        <div className={`text-start mb-8 px-4`}
          style={{ 
            zIndex: "20",
            position: "relative"
           }}
        >
          <h5 className="text-2xl font-normal mb-4">
            Interested in working with us?{" "}
            <a
              href="/contact-us"
              className={`ml-2 hover:text-primary-foreground underline underline-offset-4 text-foreground`}
            >
              Contact us
            </a>
          </h5>
        </div>
    </>
  );

  const LeftChild = (
    <>
  
    <div className="items-center justify-center text-center mx-auto mb-2 w-3/3 sm:w-1/1 md:w-1/1 lg:w-1/1 xl:w-1/2 mt-10 hidden md:block">

<img className="" src={currentImage} alt="GearHub" 
style={{ 
  zIndex: "1",
  position: "relative",

 }} 
 
/>

</div>
</>
  );


  return (
    <HeroSectionChildren id="contact-us">
    <div className="relative flex w-full flex-col items-center justify-center overflow-hidden">

      <div className={`mx-auto px-4 mt-10`}>
      <div className="text-wrap text-left w-full mb-2 lg:mb-2">
    <h2 id="contactus" className="font-normal pb-10">
    <TypingAnimation
      className="text-1xl font-normal text-black dark:text-white"
      text="Contact Us"
      duration={90}
    />
    {/* <BlurIn word="Contact Us" className={`text-1xl font-normal`} /> */}
  </h2>
          
          </div>
      <div className="flex flex-col md:flex-row w-full justify-center items-center" style={{ zIndex:10 }}>
    
    <div className="w-full md:w-1/2 text-center md:text-left px-4 md:px-8">
    {LeftChild}
     </div>
     <div className="w-full md:w-1/2 text-center md:text-right mt-4 md:mt-0 px-4 md:px-8">
      {RightChild}
     </div>  
     </div>
   
   </div>
       
        <div>
          <VelocityScroll
            text="WE BELIEVE IN TURNING IDEAS TO REALITY. |"
            default_velocity={3}
            className={`font-display text-gray-500 text-center text-4xl font-normal tracking-[0.04em]  drop-shadow-sm  md:text-8xl md:leading-[5rem] mt-8 mb-14 px-4`}
          />
        </div>
     
        <Particles
        className="absolute inset-0"
        quantity={100}
        ease={80}
        color={color}
        refresh
      />
      </div>
    </HeroSectionChildren>
  );
};

export default GetInTouchPage;
