/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from "react";
import { useTheme } from "@/wireframes/theme/theme-provider";

import { useTranslation } from "react-i18next";
import IconCloudLib from "@/wireframes/components/icon-cloud";
import BlurIn from "@/wireframes/text-animations/blur-in";
import HeroSectionChildren from "@/wireframes/page-sections/hero/HeroSectionChildren";
import Particles from "@/wireframes/special-effects/particles";

import GearHub4 from "@/assets/logo/gearhub4.png";
import TypingAnimation from "@/wireframes/text-animations/typing-animation";

const WeWorkOnPage: React.FC = () => {
  const { theme } = useTheme();
  const { t } = useTranslation();
  type ThemeStyles = {
    bgColor: string;
    textColor: string;
    imgSrc: string;
  };
  const themeStyles: Record<string, ThemeStyles> = {
    light: {
      bgColor: "bg-neutral-100",
      textColor: "text-light-text",
      imgSrc: GearHub4,
    },
    dark: {
      bgColor: "bg-dark-bg",
      textColor: "text-dark-text",
      imgSrc: GearHub4,
    },
    system: {
      bgColor: "bg-light-bg", // Default to light theme for system
      textColor: "text-light-text",
      imgSrc: GearHub4,
    },
  };
  const [color, setColor] = useState("#ffffff");
 
  useEffect(() => {
    setColor(theme === "dark" ? "#ffffff" : "#000000");
  }, [theme]);

 

  const currentThemeStyles = themeStyles[theme] || themeStyles.light;

  const [currentImage, setCurrentImage] = useState(
    currentThemeStyles.imgSrc
  );

  const handleAnimationEnd = () => {
    const randomIndex = Math.floor(Math.random() * currentThemeStyles.imgSrc.length);
    setCurrentImage(currentThemeStyles.imgSrc[randomIndex]);
  };


  const slugs = [
    "typescript",
    "react",
    "nodedotjs",
    "express",
    "amazonaws",
    "firebase",
    "nginx",
    "vercel",
    "docker",
    "git",
    "github",
    "gitlab",
    "figma",
    "solidity"
  ];

  const RightChild = (
    <div className="relative flex h-full w-full max-w-[48rem] items-center justify-center overflow-hidden rounded-lg px-15 mb-10">
      <IconCloudLib iconSlugs={slugs} />
    </div>
  );

  const LeftChild = (
    <div className={`text-lg text-star `}>
      <div className="items-center justify-center text-center mx-auto mb-2 w-1/2 sm:w-1/1 md:w-1/1 lg:w-1/1 xl:w-1/2 hidden md:block">

       <img className="" src={currentImage} alt="GearHub" 
       onAnimationIteration={handleAnimationEnd} 
       style={{ 
         zIndex: "1",
         position: "relative",
       
        }} 
        
       />

     </div>
   

    </div>
  );

  const title = (
    <h2 id="about-us" className="font-normal">
       <TypingAnimation
      className="text-1xl font-normal text-black dark:text-white"
      text="We Work on"
      duration={90}
    />
      
      {/* <BlurIn word="We Work on" className={`text-1xl font-normal`} /> */}
    </h2>
  );
  
  return (
    <HeroSectionChildren id="aboutus">
    <div className="relative flex w-full flex-col items-center justify-center overflow-hidden">

    <div className="flex flex-col md:flex-row w-full justify-center items-center" style={{ zIndex:10 }}>
    
     <div className="w-full md:w-1/2 text-center md:text-left px-4 pt-2 md:px-8 lg:pt-0 xl:pt-0 ">
        {title && (
          <div className="text-wrap text-left w-full mb-2 lg:mb-2">
            {title}
          </div>
        )}
        {LeftChild}
      </div>
      <div className="w-full md:w-1/2 text-center md:text-right  md:mt-0 px-4 md:px-8 ">
        {RightChild}
      </div>

        </div>

      <Particles
        className="absolute inset-0"
        quantity={100}
        ease={80}
        color={color}
        refresh
      />    
    </div>
 
  </HeroSectionChildren>
  );
};

export default WeWorkOnPage;
