import React, { useEffect, useState } from "react";
import { FaLaptopCode, FaGlobe } from "react-icons/fa";
import { MdDeveloperMode } from "react-icons/md";
import { LuGlobe2 } from "react-icons/lu";
import { SiBlockchaindotcom } from "react-icons/si";
import { GrHostMaintenance } from "react-icons/gr";
import HeroSectionChildren from "@/wireframes/page-sections/hero/HeroSectionChildren";
import ShineBorder from "@/wireframes/components/shine-border";
import BlurIn from "@/wireframes/text-animations/blur-in";
import { Link } from "react-router-dom";
import Particles from "@/wireframes/special-effects/particles";
import { useTheme } from "@/wireframes/theme/theme-provider";

import GearHub4 from "@/assets/logo/gearhub4.png";
import TypingAnimation from "@/wireframes/text-animations/typing-animation";


export const services = [
  {
    id: "1",
    name: "Web Application",
    slug: "web-application",
    img: <FaLaptopCode size={"5rem"} />,
    short_description:
      "We have built websites from a small static website to complete web applications like event management or e-commerce.",
  },
  {
    id: "2",
    name: "Blockchain Application",
    slug: "blockchain-application",
    img: <SiBlockchaindotcom size={"5rem"} />,
    short_description:
      "Blockchain technology has transformed industries, offering secure, transparent, and decentralized solutions. At GearHub, we specialize in blockchain development to drive innovation and elevate your business.",
  },
  {
    id: "3",
    name: "Mobile Application",
    slug: "mobile-application",
    img: <MdDeveloperMode size={"5rem"} />,
    short_description:
      "We have good experience in this domain, as we have successfully developed various applications for our clients and ourselves.",
  },
  // {
  //   id: "4",
  //   name: "Server Hosting",
  //   slug: "server-hosting",
  //   img: <FaGlobe size={"5rem"} />,
  //   short_description:
  //     "We are here to assist you to host your website or project on a server. Also, we do maintenance work for existing projects.",
  // },
  // {
  //   id: "5",
  //   name: "SEO Services",
  //   slug: "seo-services",
  //   img: <LuGlobe2 size={"5rem"} />,
  //   short_description:
  //     "Search engine optimization comes into play to put you up the line from billions of websites on the internet.",
  // },
  // {
  //   id: "6",
  //   name: "Maintenance Support",
  //   slug: "maintenance-support",
  //   img: <GrHostMaintenance size={"5rem"} />,
  //   short_description:
  //     "We do maintenance work for existing projects things like upgrading technology stack or migrating from one server to another etc.",
  // },
];


const Services: React.FC = () => {

  const title = (
    <h2 id="services" className="font-normal pb-10">
       <TypingAnimation
      className="text-1xl font-normal text-black dark:text-white"
      text="Our Services"
      duration={90}
    />
    {/* <BlurIn word="Our Services" className={`text-1xl font-normal`} /> */}
  </h2>
    
  );
  const { theme } = useTheme();

  type ThemeStyles = {
    bgColor: string;
    textColor: string;
    imgSrc: string;
  };
  const themeStyles: Record<string, ThemeStyles> = {
    light: {
      bgColor: "bg-neutral-100",
      textColor: "text-light-text",
      imgSrc: GearHub4,
    },
    dark: {
      bgColor: "bg-dark-bg",
      textColor: "text-dark-text",
      imgSrc: GearHub4,
    },
    system: {
      bgColor: "bg-light-bg", // Default to light theme for system
      textColor: "text-light-text",
      imgSrc: GearHub4,
    },
  };
  const [color, setColor] = useState("#ffffff");
 
  useEffect(() => {
    setColor(theme === "dark" ? "#ffffff" : "#000000");
  }, [theme]);

  const truncateText = (text: string, charLimit: number): string => {
    if (text.length > charLimit) {
      return text.slice(0, charLimit) + "...";
    }
    return text;
  };
  

  const currentThemeStyles = themeStyles[theme] || themeStyles.light;

  const [currentImage, setCurrentImage] = useState(
    currentThemeStyles.imgSrc
  );

  const RightChild = (
    <div className="items-center justify-center text-center mx-auto mb-2 w-1/2 sm:w-1/1 md:w-1/1 lg:w-1/1 xl:w-1/2 mt-10 hidden md:block">

    <img className="" src={currentImage} alt="GearHub" 
    style={{ 
      zIndex: "1",
      position: "relative",
    transform: "scaleX(-1)",
     }} 
     
    />
    
    </div>
  );

  const LeftChild = (
    <div className="container mx-auto px-4 pb-8">
      <div className="grid grid-cols-1 gap-8">
        {services.map((service) => (
         
          <ShineBorder
            key={service.id}
            className="flex items-center p-4 text-foreground"
            
            color={[
              `hsl(var(--shine-border-one))`,
              `hsl(var(--shine-border-two))`,
              `hsl(var(--shine-border-three))`,
            ] as Array<string>}
          >
             <div className="p-2">
            <Link to={`/services/${service.slug}`} className="flex w-full">
              <div className="flex-none text-foreground">{service.img}</div>
              <div className="flex-grow ms-4">
                <h2 className="text-lg md:text-base/7 lg:text-2xl capitalize mb-2 text-foreground">{service.name}</h2>
                <p className="text-sm font-normal text-accent-foreground">
                  {truncateText(service.short_description,100)}
                </p>
              </div>
            </Link>
          </div>

          </ShineBorder>
        ))}
      </div>
    </div>
  );

  return (

    <HeroSectionChildren id="services">
    <div className="relative flex  w-full flex-col items-center justify-center overflow-hidden">

    <div className="flex flex-col md:flex-row w-full justify-center items-center" style={{ zIndex:10 }}>
    
     <div className="w-full md:w-1/2 text-center md:text-left px-4 md:px-8  ">
        {title && (
          <div className="text-wrap text-left w-full mb-2 lg:mb-2">
            {title}
          </div>
        )}
        {LeftChild}
      </div>
      <div className="w-full md:w-1/2 text-center md:text-right mt-4 md:mt-0 px-4 md:px-8">
        
     {RightChild}
      </div>


      <Particles
        className="absolute inset-0"
        quantity={100}
        ease={80}
        color={color}
        refresh
      />
      </div>
    
    </div>
 
  </HeroSectionChildren>
  
  );
};

export default Services;
