/* eslint-disable @typescript-eslint/no-unused-vars */
import { useParams } from "react-router-dom";
import services from "@/datasets/services.json";
import HeroSectionWithProps from "@/wireframes/page-sections/hero/HeroSectionWithProps";
import { useEffect, useState } from "react";
import DotPattern from "@/wireframes/backgrounds/pattern/dot-pattern";
import { useTheme } from "@/wireframes/theme/theme-provider";
import { cn } from "@/lib/utils";
import Particles from "@/wireframes/special-effects/particles";
import HeroSectionChildren from "@/wireframes/page-sections/hero/HeroSectionChildren";
import GradualSpacing from "@/wireframes/text-animations/gradual-spacing";
import GearHub2 from "@/assets/logo/gearhub2.png";
import TypingAnimation from "@/wireframes/text-animations/typing-animation";

const ServiceDetails: React.FC = () => {
  let { slug } = useParams<{ slug: string }>();
  const service = services.find((service) => service?.slug === slug);

  const { theme } = useTheme();

  const [color, setColor] = useState("#ffffff");
  type ThemeStyles = {
    bgColor: string;
    textColor: string;
    imgSrc: string;
  };
  const themeStyles: Record<string, ThemeStyles> = {
    light: {
      bgColor: "bg-neutral-100",
      textColor: "text-light-text",
      imgSrc: GearHub2,
    },
    dark: {
      bgColor: "bg-dark-bg",
      textColor: "text-dark-text",
      imgSrc: GearHub2,
    },
    system: {
      bgColor: "bg-light-bg", // Default to light theme for system
      textColor: "text-light-text",
      imgSrc: GearHub2,
    },
  };

  const currentThemeStyles = themeStyles[theme] || themeStyles.light;

  const [currentImage, setCurrentImage] = useState(currentThemeStyles.imgSrc);

  useEffect(() => {
    setColor(theme === "dark" ? "#ffffff" : "#000000");
  }, [theme]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  if (!service) {
    return (
      <div className="text-center justify-center items-center">
        Service not found
      </div>
    );
  }

 

  const RightChild = (
    <div className="relative flex w-full flex-col items-center justify-center overflow-hidden hidden md:block">
      <span className="pointer-events-none whitespace-pre-wrap bg-gradient-to-b from-white to-gray-300/80 bg-clip-text text-center text-2xl font-semibold leading-none ">
        <div className="items-center justify-center text-center mx-auto mb-2 w-1/2 sm:w-1/2 md:w-1/2 lg:w-1/2 xl:w-1/2 ">
          <img
            className="bounce"
            src={currentImage}
            alt="GearHub"
            style={{
              zIndex: "1",
              position: "relative",
            }}
          />
        </div>
      </span>
    </div>
  );

  const LeftChild = (
    <div className="w-full md:w-1/1 text-center md:text-left px-4 md:px-8 pt-10 lg:pt-20 xl:pt-20 ">
      <header className={`text-start mb-8 px-4 sm:my-10 pt-10`}>
      <TypingAnimation
      className="text-3xl sm:text-3xl md:text-3xl lg:text-5xl text-wrap text-start font-bold text-black dark:text-white"
      text={service.name ? service.name : "Service not found"}
      duration={90}
    />
        {/* <GradualSpacing
          className="text-3xl sm:text-3xl md:text-3xl lg:text-5xl text-wrap text-start leading-normal font-bold mt-20 sm:mt-12 md:mt-8 lg:mt-10"
          text={service.name ? service.name : "Service not found"}
        /> */}
      </header>

      <section className="mb-10 px-4">
        <p className={`text-lg text-start max-w-3xl sm:text-md`}>
          {service.description ? service.description : "Service not found"}
        </p>
      </section>
    </div>
  );

  return (
    <HeroSectionChildren id="service-slug">
      <div className="flex flex-col md:flex-row w-full justify-center items-center sm:w-1/1 md:w-1/1">
        <div className="w-full md:w-1/2 text-center md:text-left pb-5 sm:pb-25">
          {LeftChild}
        </div>
        <div className="w-full md:w-1/2  text-center md:text-right md:mt-0 px-4 md:px-8">
          {RightChild}
        </div>
      </div>
      <Particles
        className="absolute inset-0"
        quantity={100}
        ease={80}
        color={color}
        refresh
      />
    </HeroSectionChildren>
  
  );
};

export default ServiceDetails;
