import React, { useEffect, useRef, useState } from "react";
import { useTheme } from "@/wireframes/theme/theme-provider";
import { ThemeToggle } from "@/wireframes/theme/theme-toggle";
import { LangToggle } from "@/i18n/lang-toggle";
import { useTranslation } from "react-i18next";
import GearHubDark from "@/assets/logo/gearHubLight.png";
import GearHubLight from "@/assets/logo/gearHubDark.png";
import { Button } from "@/components/ui/button";
import { Link } from "react-router-dom";

const Header: React.FC = () => {
  const { theme } = useTheme();
  const [isOpen, setIsOpen] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const { t } = useTranslation();
  const dropdownRef = useRef<HTMLDivElement>(null);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };

  const closeDropdown = () => {
    setDropdownOpen(false);
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (
      dropdownRef.current &&
      !dropdownRef.current.contains(event.target as Node)
    ) {
      closeDropdown();
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const themeBgClass = theme === "dark" ? "bg-black" : "bg-white";

  return (
    <nav
      className={`fixed top-0 left-0 w-full z-10 bg-background`}
      style={{ zIndex: 100, height: "4rem" }}
    >
      <div className="container-fluid mx-auto px-4 sm:px-6 lg:px-8">
        <div className="relative flex h-16 items-center justify-between border-b border-gray-200">
          <div className="flex items-center">
            <div className="flex-shrink-0">
              <a href="/#home">
                <div style={{ display:"flex", alignItems:"center" }}>
                <img
                  className="h-11 w-auto"
                  src={theme === "dark" ? GearHubDark:GearHubLight}
                  alt="GearHub Logo"
                /> 
                <div className="text-2xl text-foreground ml-2">
                  GearHub
                </div>
                                </div>
              </a>
            </div>
          </div>
          <div className="flex items-center lg:hidden">
            <Button
              variant="outline"
              size="icon"
              type="button"
              onClick={toggleMenu}
              className={`inline-flex items-center justify-center text-foreground rounded-md  p-2  focus:outline-none hover:border-gray-200 hover:text-foreground hover:bg-background`}
              aria-controls="mobile-menu"
              aria-expanded={isOpen}
            >
              <span className="sr-only">Open main menu</span>
              <svg
                className={`${isOpen ? "hidden" : "block"} h-6 w-6`}
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="currentColor"
                aria-hidden="true"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5"
                ></path>
              </svg>
              <svg
                className={`${isOpen ? "block" : "hidden"} h-6 w-6`}
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="currentColor"
                aria-hidden="true"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M6 18L18 6M6 6l12 12"
                ></path>
              </svg>
            </Button>
            <div className="p-1 ml-2">
              <ThemeToggle />
            </div>
          </div>
          {/* Mobile menu */}
          <div
            className={`${
              isOpen ? "block" : "hidden"
            } lg:hidden absolute top-16 w-full bg-primary-foreground text-background`}
          >
           <div className="px-2 pt-2 pb-3 space-y-1">
              <a
                href="/"
                className={`block px-3 py-2 rounded-md text-foreground font-medium hover:text-muted-background`}
              >
                Home
              </a>
              <a
                href="/about-us"
                className={`block px-3 py-2 rounded-md text-foreground font-medium hover:text-muted-background`}
              >
                About Us
                </a>
              <a
                href={`/services-page`}
                className={`block px-3 py-2 rounded-md text-foreground font-medium hover:text-muted-background`}
              >
                Services
                </a>
              <a
                href="/contact-us"
                className={`block px-3 py-2 rounded-md text-foreground font-medium hover:text-muted-background`}
              >
                Contact Us
              </a>
            </div>
          </div>
          {/* Desktop navigation */}
          <div className="hidden lg:flex lg:items-center lg:justify-end xl:justify-end">
          <div className="space-x-4">
              <a
                href="/"
                className={`px-3 py-2 rounded-md text-foreground text-sm font-medium hover:text-muted-foreground`}
              >
                Home
                </a>
              <a
                href="/about-us"
                className={`px-3 py-2 rounded-md text-foreground text-sm font-medium hover:text-muted-foreground`}
              >
                About Us
                </a>
              <a
                href="/services-page"
                className={`px-3 py-2 rounded-md text-foreground text-sm font-medium hover:text-muted-foreground`}
              >
                Services
                </a>
              <a
                href="/contact-us"
                className={`px-3 py-2 rounded-md text-foreground text-sm font-medium hover:text-muted-foreground`}
              >
                Contact Us
              </a>
            </div>
                  </div>
          <div className="hidden lg:flex lg:items-center">
            <div className="p-1 mt-2">
              <ThemeToggle />
            </div>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Header;
